import React from "react";
import AccessibilityCards from "../components/AccessibilityCards";
import '../styles/accessibilite.css';

const Accessibilite = () => {    
  return (
    <div className="body">
        <div className="header-container">
            <div className="left">
                <div className="inner">
                    <h1>Accessibilité</h1>
                    <p>Le CASCO se construit autour des besoins de tous !</p>
                </div>
            </div>
        </div>
        <div className="accessibility-content">
            <AccessibilityCards/>
            <div className="accessibility-description">
                <p> 
                    Vous accueillir c’est penser au mieux toutes situations d’inconfort, de difficultés ou d’handicap. 
                    Nous espérons donc que chacun se sentira inclus dans cet espace que nous avons voulu le plus convivial possible. 
                    Afin que les moments d’attente vous soient le plus profitables possible, des espaces de travail et de repos sont disponibles. 
                </p>
            </div>
        </div>
    </div>
  );
};

export default Accessibilite;
