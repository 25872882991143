import React from "react";
import ArticleImage4 from "../assets/Noel casco 2024.jpeg";


const Article4 = () => {
  return (
    <div className="actualites-main-container">
      <h3 className="actualites-page-title"> Rejoignez-nous pour notre Noël ce 19 décembre
      </h3>
      <div className="actualites-posts-wrapper">
        <img src={ArticleImage4}
          alt="flyer de présentation apéro CASCO"
          className="actualites-invit"
          style={{margin: "auto"}}
        />
        <div className="actualites-invit-description">
          <p>
            À toutes et tous, 
            <br/> <br/>
            L’hiver arrive à grand pas et ses réjouissances aussi, c’est pour nous l’occasion de nous réunir autour des festivités de fin d’année ! 
            <br/> <br/>
            Au programme ? vin chaud et hot-dog, élection du plus « magnifique » pull de Noël avec des cadeaux pour le ou la vainqueur !
            <br/> <br/>
            On vous attend donc jeudi 19 décembre à partir de 18h avec vos plus beaux habits de fête…
            <br/> <br/>
            Pour la pré-commande de votre hot-dog et boisson, c’est par ici : 
            <a href="https://jj9e0n3m.forms.app/formulaire-de-pre-commande" target="_blank" rel="noreferrer"> formulaire</a>
            <br/> <br/>
            On se réjouit de vous voir,
            <br/>
            <b>Toute l’équipe du CASCO</b>
          </p>    
        </div>
      </div>
    </div>
  );
};

export default Article4;
